<template>
  <div class="container">
    <div class="columns is-centered">
      <div class="column is-half">
        <h2 class="title has-text-weight-bold has-text-centered mt-6 mb-3">
          Accès internet
        </h2>

        <div v-for="item in items" class="card my-5">
          <footer class="card-footer">
            <p class="card-footer-item is-justify-content-left ml-4">
              <span>
                <figure class="image is-30x30">
                <i class="fad fa-wifi-1 fa-1x"></i>
                </figure>
                <p class="is-italic has-text-weight-light is-size-7">
                  Nom du réseau
                </p>
                <p class="has-text-weight-bold is-size-5 has-text-grey">
                  {{ item.nom }}
                </p>
              </span>
            </p>
            <p class="card-footer-item is-justify-content-left ml-4">
              <span>
                <i class="fad fa-wifi-1 fa-1x" style="color: #ffffff"></i>
                <p class="is-italic has-text-weight-light is-size-7">
                  Mot de passe
                </p>
                <p class="is-size-5">{{ item.mdp }}</p>
              </span>
            </p>
          </footer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        { nom: "SO_FOOT", mdp: "Spr@75011Larry_foot" },
        { nom: "SO_FOOT GUEST", mdp: "bienvenue" },
      ],
    };
  },
};
</script>